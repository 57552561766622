import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import CloseIconSearch from "@/components/common/ui/icons/CloseIconSearch";
import { gsap } from "gsap";

const SearchComponent = ({ onClick,searchItemRef }) => {
    const [searchInput, setSearchInput] = useState('');


    const searchSubmit = (e) => {
        e.preventDefault();
        if (searchInput === '') {
            return;
        } else {
            window.location.href = `/search?keyword=${searchInput}`;
            document.querySelector('.search-desktop').classList.remove('search-open');
            gsap.to(searchItemRef.current, {
                duration: .4, height: 0, opacity: 0, overflow: 'hidden'
            });
            gsap.to(searchItemRef.current.querySelector('.container'), {
                opacity: 0,
            }, '-=.4');
            gsap.to(searchItemRef.current, {
                display: 'none'
            });
        }
        // setSearchInput('')


    };



    const handleInput = (e) => {
        setSearchInput(e.target.value);
    };
    const handleCLick = (e) => {
        if (onClick) {
            onClick(e);
        }
    };

    return (
        <div className="search-desktop" ref={searchItemRef}>
            <Container>
                <div onClick={handleCLick} className="clickto clickto_close">
                    <CloseIconSearch/>
                </div>
                <Form onSubmit={searchSubmit}>
                    <Form.Group className="search-input form-group">
                        <Form.Control
                            value={searchInput}
                            onChange={e => handleInput(e)}
                            type="text"
                            placeholder="Type here for your search"
                        />
                        <button type="submit">Search</button>
                    </Form.Group>
                </Form>
            </Container>
        </div>
    );
};

export default React.memo(SearchComponent);
