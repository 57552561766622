'use client'
import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {hover, text, Transition, white} from "@/styles/globalStyleVars";
import KeyPopUp from "@/components/common/forms/KeyPopUP";
import {usePathname} from "next/navigation";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import StickyIconText from "@/components/common/ui/icons/StickyIconText";
import Link from "next/link";
import KeyPopUPIelts from "@/components/common/forms/KeyPopUPIelts";
import {useShowSetting} from "@/app/ShowSettingProvider";
import useDeviceTypeMobile from "@/components/hooks/useDeviceTypeMobile";


const StickyButton = () => {
    gsap.registerPlugin(ScrollTrigger);
    const [offset, setOffset] = useState(90);
    const pathName = usePathname();
    const [show, setShow] = useState(false);
    const [showFlag, setShowFlag] = useState(false);
    const [showMigration, setShowMigration] = useState(false);
    const [showUniversity, setShowUniversity] = useState(null);
    const [showCourse, setShowCourse] = useState(null);
    const [showSchool, setShowSchool] = useState(null);
    const isMobile = useDeviceTypeMobile()
    const handleClose = () => {
        setShow(false)
        setShowUniversity('')
        setShowCourse('')
        setShowSchool('')
    };
    const handleShow = () => {

        const isUniversity = pathName.startsWith('/universities/');
        const isCourse = pathName.startsWith('/courses/');
        const isSchool = pathName.startsWith('/schools/');

        if(isUniversity){
            // Select the h1 element
            const heading = document.querySelector('.intro .intro-left h1');
            // Get the value (text content)
            setShowUniversity(heading.textContent);
        }
        if(isCourse){
            // Select the h1 element
            const headingCourse = document.querySelector('.intro .intro-left h1');
            // Get the value (text content)
            setShowCourse(headingCourse.textContent);

        }
        if(isSchool){
            // Select the h1 element
            const headingCourse = document.querySelector('.intro .intro-left h1');
            // Get the value (text content)
            setShowSchool(headingCourse.textContent);

        }

        setShow(true);

    };

    useEffect(() => {
        const isDestination = pathName.startsWith('/destination/') && pathName.split('/').filter(Boolean).length === 2;
        const isMigration = pathName === "/migrate-to-australia";

        // Reset flags on path change
        setShowFlag(isDestination);
        setShowMigration(isMigration);

        // Optionally reset the `show` state if necessary when route changes
        if (!isDestination && !isMigration) {
            setShow(false);
        }

       const isUniversity = pathName.startsWith('/universities/');
       const isCourse = pathName.startsWith('/courses/');
       const isSchool = pathName.startsWith('/schools/');

        if(isUniversity){
            // Select the h1 element
            const heading = document.querySelector('.intro .intro-left h1');
            // Get the value (text content)
            setShowUniversity(heading.textContent);
        }
        if(isCourse){
            // Select the h1 element
            const headingCourse = document.querySelector('.intro .intro-left h1');
            // Get the value (text content)
            setShowCourse(headingCourse.textContent);

        }
        if(isSchool){
            // Select the h1 element
            const headingCourse = document.querySelector('.intro .intro-left h1');
            // Get the value (text content)
            setShowSchool(headingCourse.textContent);

        }


        ScrollTrigger.refresh();  // Refresh ScrollTrigger after animation

    }, [pathName]);

    const handleScrollClick = (hash) => {
        if (hash) {
            setTimeout(() => {
                gsap.to(window, {duration: 0.7, scrollTo: hash});
                ScrollTrigger.refresh();
            }, 300); // Adding a slight delay
        }
    };


    return (
        <>
            <KeyPopUp title={showUniversity} schools={showSchool} courseName={showCourse} show={show} handleClose={handleClose}/>


            <StyledSticky offset={offset} className='stickyButton'>
                {
                    showFlag ?
                        <div onClick={() => handleScrollClick('#destination_form')} onTouchStart={() => handleScrollClick('#destination_form')} className="flex stickyButton">

                            <StickyIconText data={'How can we help?'}/>
                        </div>
                        : showMigration ?
                            <div onClick={() => handleScrollClick('#migration_form')} onTouchStart={() => handleScrollClick('#migration_form')} className="flex stickyButton">
                                <StickyIconText data={'How can we help?'}/>
                            </div>

                            :
                            <div onClick={handleShow} className="flex stickyButton othes">
                                <StickyIconText data={'How can we help?'}/>
                            </div>
                }
            </StyledSticky>
        </>
    );
};

const StyledSticky = styled.div`
    background: ${hover};
    position: fixed;
    right: ${(p) => p.offset}px;
    bottom: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 14px 12px;
    z-index: 9999;

    .flex {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            margin-left: 8px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.16px;
            position: relative;
            overflow: hidden;
            color: ${hover};

            &:before {
                transition: 0.6s all ${Transition};
                content: 'How can we help?';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                transform: translateY(0);
                color: ${white};
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.16px;
            }

            &:after {
                transition: 0.6s all ${Transition};
                content: 'How can we help?';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                transform: translateY(100px);
                color: ${text};
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.16px;
            }

        }

        svg {
            animation-name: shake;
            animation-timing-function: linear;
            animation-duration: .5s;
            animation-iteration-count: infinite;
            animation-delay: .5s;
        }
    }

    .stickyButton {
        cursor: pointer;
    }

    @keyframes shake {
        0%,
        100% {
            transform: rotate(0deg);
        }
        20%,
        60% {
            transform: rotate(6deg);
        }
        40%,
        80% {
            transform: rotate(-6deg);
        }
    }

    &:hover {
        .flex {
            p {
                color: ${hover};

                &:before {
                    transform: translateY(-100px);

                }

                &:after {
                    transform: translateY(0);
                }
            }
        }
    }

    @media (max-width: 992px) {
        right: 20px !important;
    }
    @media (max-width: 767px) {
        right: 15px !important;
    }
`;

export default React.memo(StickyButton);
