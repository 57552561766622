"use client";
import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Container} from "react-bootstrap";
import {hardGray, hover, softGray, softWhite, text, Transition, white} from "@/styles/globalStyleVars";
import {CSSPlugin, gsap} from "gsap";
import {usePathname} from "next/navigation";
import Logo from "@/components/common/globals/Logo";
import MenuItems from "@/components/common/globals/menu/MenuItems";
import ButtonComponent from "@/components/common/globals/menu/ButtonComponent";
import SearchComponent from "@/components/common/globals/menu/SearchComponent";
import {Loading} from "@/components/common/Loading";

function Component({region, data, onClick, setShowLoader}) {
    gsap.registerPlugin(CSSPlugin);
    const pathName = usePathname()
    const toggleDivRef = useRef(null);
    const [isToggled, setIsToggled] = useState(false);
    const [isToggledSubmenu, setIsToggledSubmenu] = useState(false);
    const [isToggledLanguage, setIsToggledLanguage] = useState(region);
    const searchItemRef = useRef();
    const [showLoaderTrue, setShowLoaderTrue] = useState(setShowLoader);


    const handleToggle = () => {
        setIsToggled(prevState => !prevState);
    };
    const handleToggleSubMenu = () => {
        setIsToggledSubmenu(prevState => !prevState);
    };
    // Function to toggle the language state
    const handleToggleLanguage = (language) => {
        setShowLoaderTrue(true)
        setIsToggledLanguage(language);
        localStorage?.setItem('region',JSON.stringify(language));
        if(onClick){
            onClick(language)
        }

    };
    // Function to handle clicks outside the toggleDivRef
    const handleClickOutside = (event) => {
        if (toggleDivRef.current && !toggleDivRef.current.contains(event.target)) {
            // document.body.style.overflow = 'auto';

            setIsToggled(false);
            setIsToggledSubmenu(false)
        }
    };

    function handleOutsideClick(e) {
        setIsToggled(false);
        setIsToggledSubmenu(false)

    }

    const closeSearchBox = () => {
        if (searchItemRef.current.classList.contains('search-open')) {
            document.querySelector('body').classList.remove('open_menu');
            document.body.classList.remove('menu-open');
            searchItemRef.current.classList.remove('search-open');
            gsap.to(searchItemRef.current, {
                duration: 0.4,
                height: 0,
                opacity: 0,
                overflow: 'hidden',
            });
            gsap.to(searchItemRef.current.querySelector('.container'), {
                opacity: 0,
            }, '-=.4');
            gsap.to(searchItemRef.current, {
                display: 'none',
            });

        }
    };


    // menu hide show on scroll action
    useEffect(() => {
        if (document.body.classList.contains("scroll-down")) {
            document.body.classList.remove("scroll-down");
        }
    },[pathName]);
    useEffect(() => {
        const body = document.body;
        const scrollUp = "scroll-up";
        const scrollDown = "scroll-down";
        let lastScroll = 0;
        let howMuchScroll = 50;

        window.addEventListener("scroll", () => {
            let currentScroll = window.pageYOffset;

            if (currentScroll <= howMuchScroll) {
                body.classList.remove(scrollUp);
                return;
            }
            if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            } else if (
                currentScroll < lastScroll &&
                body.classList.contains(scrollDown)
            ) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }
            lastScroll = currentScroll;
        });
    }, []);
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [pathName]);



    useEffect(() => {
        if (localStorage?.getItem('region')) {
            setIsToggledLanguage(JSON.parse(localStorage?.getItem('region')))
        }
    }, [pathName]);



    return (
        <StyleComponent className="main-menu" ref={toggleDivRef} >

            <Container>
                {/* logo */}
                <Logo/>
                <MenuItems
                    handleOutsideClick={handleOutsideClick}
                    pathName={pathName}
                    isToggled={isToggled}
                    handleToggle={handleToggle}
                    handleToggleSubMenu={handleToggleSubMenu}
                    isToggledSubmenu={isToggledSubmenu}
                />
                <ButtonComponent onClick={handleToggleLanguage} searchItemRef={searchItemRef} selectedRegion={isToggledLanguage} region={data?.region} handleToggle={handleToggle} isToggledLanguage={isToggledLanguage} isToggled={isToggled}/>
            </Container>
            <SearchComponent onClick={closeSearchBox}  searchItemRef={searchItemRef}/>

        </StyleComponent>
    );
}
const StyleComponent = styled.section`
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    transition: all 0.6s ease;

    @media(max-width: 1170px){
        display: none;
    }
    
    .first_button_hover_migrate_button{
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        
        &:after{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            content: '';
            border-radius: 50%;
            
        }


        &:hover{
            &:after{
                opacity: 0;
            }
            .active{
                #Ellipse_21 {
                    r: 7.5px;
                }

                #Ellipse_20 {
                    stroke: ${hover};
                }
            }
        }
    }
    .container {
        display: flex;
        justify-content: space-between;
        height: 100px;
        align-items: center;
        z-index: 9;
        position: relative;
    }

    .main-menu__logo {
        img {
            &:nth-last-child(1) {
                display: none;
            }
        }
    }

    .main-menu__items {
        ul {
            display: flex;

            li {
                a {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.16px;
                    font-weight: 500;
                    color: #fff;
                    display: inline-flex;
                    align-items: center;
                    position: relative;

                    &:after {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 1px;
                        content: '';
                        background: white;
                        opacity: 0;
                        transition: 0.7s all ${Transition};

                    }

                    svg {
                        margin-left: 8px;

                        #Ellipse_21, #Ellipse_20 {
                            transition: 0.7s all ${Transition};
                        }
                    }

                    &.active {
                        &:after {
                            opacity: 1;
                        }
                    }

                    &:hover {
                        #Ellipse_21 {
                            r: 7.5px;
                        }
                        
                        #Ellipse_20 {
                            stroke: ${hover};
                        }
                    }
                }

                svg{
                    transition: 0.6s all ${Transition};
                }
            
                &:not(:nth-last-child(1)) {
                    margin-right: 28px;
                }
                
                .parent-title{
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.16px;
                    font-weight: 500;
                    color: #fff;
                    display: inline-flex;
                    align-items: center;
                    position: relative;
                    cursor: pointer;

                    &:after {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 1px;
                        content: '';
                        background: white;
                        opacity: 0;
                        transition: 0.7s all ${Transition};

                    }

                    svg {
                        margin-left: 8px;

                        #Ellipse_21, #Ellipse_20 {
                            transition: 0.7s all ${Transition};
                        }
                    }

                    &.active {
                        &:after {
                            opacity: 1;
                        }
                    }

                    &:hover {
                        #Ellipse_21 {
                            r: 7.5px;
                        }

                        #Ellipse_20 {
                            stroke: ${hover};
                        }
                    }
                    
                }
            

                &.has-child {

                    &.ON {
                        .menu-desktop__items__slide {
                            height: auto !important;
                          
                            opacity: 1 !important;
                            visibility: visible !important;
                            transition: 0.3s height, 0.5s opacity, 0.6s padding ${Transition};

                        }

                        .open-menu,
                        .menu-desktop__items__slide__subs,
                        .as-join {
                            transform: translateY(0);
                            opacity: 1;
                        }
                    }

                    &.OFF {
                        &.ON {
                            .menu-desktop__items__slide {
                                height: 0 !important;
                                padding: 0 !important;
                                margin-top: 0 !important;
                                opacity: 0 !important;
                                visibility: hidden !important;
                                transition: 0.3s height, 0.5s opacity, 0.6s padding ${Transition};

                            }

                            .open-menu,
                            .menu-desktop__items__slide__subs,
                            .as-join {
                                transform: translateY(-25px);
                                opacity: 0;
                            }
                        }
                    }

                    &:hover {

                    }
                }
            }
            
         
        }
    }

    .main-menu__right_sider {
        display: flex;
        align-items: center;
        gap: 30px;
    }

    .main-menu__button {
        display: flex;
        gap: 15px;

        .migration-button {
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            position: relative;
            overflow: hidden;
            background: white;

            &.ON {
                overflow: visible;

                li {
                    opacity: 1;
                }
            }

            li {
                margin-bottom: 12px;
                transition: 0.6s all ${Transition};
                position: absolute;
                top: 52px;
                opacity: 0;

                &.active {
                    opacity: 1;
                    position: absolute;
                    top: 0;

                }

                &:hover {
                    #Bd-2, #Au-2 {
                        fill: ${white};
                    }

                    #Ellipse_18 {
                        r: 20px;
                    }
                }
            }

            #Ellipse_18, #Bd-2 {
                transition: 0.6s all ${Transition};

            }

            .first_button_hover{
        
            }

        }

        .search {
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 50%;

            #Ellipse_19 {
                transition: 0.6s all ${Transition};
            }

            &:hover {
                #Ellipse_19 {
                    r: 20px;
                }
            }
        }
    }

    .menu-desktop__items__slide {
        width: 100%;
        overflow: hidden;
        max-width: 340px;
        background: ${white};
        position: absolute;
        border-radius: 8px;
        top: 82px;
        height: 0;
        padding:  28px !important;
        margin-top: 20px !important;
        visibility: hidden;
        opacity: 0;
        display: none;
        box-shadow: 0 10px 20px rgba(0 ,0 ,0 , 10%);
        ul {
            flex-direction: column;

            li {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: ${text} !important;
                    display: flex;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 32px;
                    letter-spacing: -0.24px;

                    img {
                        padding-left: 5px;
                        opacity: 0;
                        transition: 0.6s ${Transition};
                    }

                    &:hover {
                        color: ${hover} !important;

                        img {
                            opacity: 1;
                            margin-left: 10px;
                        }
                    }
                }

                &:not(:nth-last-of-type(1) a) {
                    margin-bottom: 16px;
                }
            }
        }


        &__subs {
            background-color: ${softWhite};
            padding: 20px;
            border-radius: 8px;
            overflow: hidden;

            h4 {
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.16px;
                color: ${hardGray};
                padding-bottom: 13px;
                margin-bottom: 13px;
                border-bottom: 1px solid rgba(54, 50, 49, 0.1);
                font-weight: 500;

            }

            ul {
                li {
                    margin-bottom: 12px !important;
                    position: relative;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.16px;
                    cursor: pointer;
                    font-weight: 500;

                    img {
                        padding-left: 5px;
                        opacity: 1;
                        transition: 0.6s ${Transition};
                    }

                    a {
                        font-size: 16px;
                        line-height: 20px;
                    }

                    &:nth-last-of-type(1) {
                        margin-bottom: 0 !important;
                    }

                    &:hover {
                        img {
                            opacity: 1;
                            margin-left: 10px;
                        }
                    }
                }
            }

            &.as-join {
                margin-top: 28px !important;
                padding-top: 12px;
                padding-bottom: 12px;
                border: 1px solid ${hardGray};
                position: relative;
                overflow: hidden;
                background: white;

                &:after {
                    position: absolute;
                    inset: 0;
                    background: ${hover};
                    height: 100%;
                    width: 100%;
                    content: '';
                    transition: 0.7s all ${Transition};
                    transform: translateX(-100%);
                    border-radius: 8px;

                }

                &:hover {
                    &:after {
                        transform: translateX(0);
                    }
                    border: 1px solid ${hover};

                    li {
                        a {
                            color: ${white} !important;

                            img {
                                &:first-child {
                                    opacity: 0;
                                }

                                &:last-child {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                }

                li {
                    margin-bottom: 0 !important;
                    position: relative;
                    z-index: 2;

                    a {
                        position: relative;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.16px;
                        font-weight: 500;

                        img {
                            opacity: 1;
                            right: 0;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            margin: auto 0 !important;

                            &:first-child {
                                opacity: 1;
                            }

                            &:last-child {
                                opacity: 0;
                            }
                        }

                        &:hover {
                            img {
                                right: -10px;
                            }
                        }
                    }
                }
            }
        }

        //animation initial stage
        .as-join {
            transform: translateY(0) !important;
            opacity: 0;
        }
        .open-menu,
        .menu-desktop__items__slide__subs
         {
            transform: translateY(-25px);
            opacity: 0;
        }
    }


    .search-desktop {
        //height: 195px;
        background-color: ${white};
        //display: flex;
        align-content: center;
        flex-wrap: wrap;
        position: fixed;
        width: 100%;
        left: 0;
        right: 0;
        top: 95px;
        height: 0;
        display: none;
        opacity: 0;
        overflow: hidden;
        z-index: 9;
        box-shadow: 0 1px 6px rgb(0 0 0 / 10%);


        .clickto_close {
            cursor: pointer;

            svg {
                fill: black;
            }
        }

        .container {
            position: relative;
            opacity: 0;
        }

        position: absolute;

   


        form {
            min-width: 100%;

            .form-group {
                position: relative;
                display: flex;
                border: 1px solid ${hardGray};
                border-radius: 30px !important;
                height: 60px !important;
                align-items: center;
                margin: 0;

                .form-control {
                    height: 50px !important;
                    background-color: transparent;
                    border-radius: 0;
                    padding: 0 20px 0;
                    line-height: 50px; /* 20px / 15px = 1.3333rem */
                    color: ${text};
                    border: none !important;
                    margin: 0;

                    ::placeholder {
                        line-height: 21px; /* 20px / 15px = 1.3333rem */
                        color: ${text};
                        outline: none;
                        border: none;

                    }

                    :focus {
                        outline: none;
                        outline-color: transparent;
                        border-color: white;
                    }
                }

            }

            button {
                display: flex;
                align-items: center;
                color: ${white};
                margin: 0 5px 0 0;
                padding: 20px 30px;
                line-height: 21px;
                text-transform: capitalize;
                height: 50px;
                background-color: ${text};
                position: relative;
                border-radius: 30px;
                z-index: 0;
                transition: 0.7s all ${Transition};
                border: 1px solid ${softGray};
                overflow: hidden;
                text-align: center;
                justify-content: center;

                &:after {
                    content: '';
                    background: ${hover};
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    border-radius: 30px;
                    width: 100%;
                    height: 100%;
                    margin: auto;
                    z-index: -1;
                    transform: translateX(-100%);
                    transition: 0.7s all ${Transition};


                }

                a {
                    &:after {
                        display: none;
                    }

                    &:before {
                        display: none;
                    }
                }


                &:hover {
                    color: ${white} !important;

                    border: 1px solid ${hover};


                    &:after {
                        opacity: 1;
                        transform: translateX(0);

                    }
                }


            }

            .search-input {

                span {
                    position: absolute !important;
                    bottom: 18px;
                }


            }

            &__menu {
                margin-top: 23px;
                min-width: 100%;

                p {
                    font-size: 12px;
                    color: #ed1b34;
                    font-weight: 600;
                    line-height: 18px;
                    margin-bottom: 13px;

                }

                ul {
                    display: inline-flex;

                    li {
                        a {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 22px;
                            color: #221f1f;
                            display: flex;
                            margin-right: 30px;
                        }

                        &:nth-last-child(1) {
                            a {
                                margin-right: 0;
                            }
                        }
                    }
                }

            }

           

        }

        .CloseIconSearchSvg{
            position: absolute;
            right: 15px;
            top: -30px;
            z-index: 44;
        }

`;
export default React.memo(Component)