import React from "react";
import reactHtmlParser from "react-html-parser";

const StickyIconText = ({data}) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <defs>
                    <clipPath id="clip-path">
                        <rect id="Rectangle_7074" data-name="Rectangle 7074" width="20" height="20" transform="translate(12 12)" fill="#fff" stroke="#707070" strokeWidth="1"/>
                    </clipPath>
                </defs>
                <g id="Icon" transform="translate(-12 -12)" clipPath="url(#clip-path)">
                    <g id="Group_25672" data-name="Group 25672" transform="translate(12 12)">
                        <path id="Path_9196" data-name="Path 9196" d="M12,2A10,10,0,1,0,22,12,10.016,10.016,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20Z" transform="translate(-2 -2)" fill="#fff"/>
                        <path id="Path_9197" data-name="Path 9197" d="M12.5,6.04A3,3,0,0,0,9,9a1,1,0,1,0,2,0,.992.992,0,0,1,1.17-.98A1.1,1.1,0,0,1,13,9.11a1.167,1.167,0,0,1-.34.82A5.687,5.687,0,0,0,11,14.01a.978.978,0,0,0,1,.97,1.029,1.029,0,0,0,1-1.03,3.646,3.646,0,0,1,1.08-2.6,3.144,3.144,0,0,0-1.58-5.3Z" transform="translate(-2 -2)" fill="#fff"/>
                        <path id="Path_9198" data-name="Path 9198" d="M12.71,16.29a1.5,1.5,0,0,0-.15-.12.757.757,0,0,0-.18-.09.6.6,0,0,0-.19-.06,1,1,0,0,0-.9.27,1.032,1.032,0,0,0-.21.33.99.99,0,0,0,.21,1.09,1.155,1.155,0,0,0,.33.21.942.942,0,0,0,.76,0,1.155,1.155,0,0,0,.33-.21.99.99,0,0,0,.21-1.09,1.032,1.032,0,0,0-.21-.33Z" transform="translate(-2 -2)" fill="#fff"/>
                    </g>
                </g>
            </svg>
            <p>{reactHtmlParser(data)}</p>
        </>
    );
};

export default React.memo(StickyIconText);
