import React, {useContext, useEffect, useState} from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import {hover, text, Transition, white} from "@/styles/globalStyleVars";
import {TimelineLite} from 'gsap';
import {usePathname} from "next/navigation";
import Button from "@/components/common/ui/Button";
import reactHtmlParser from "react-html-parser";
import SelectRegionFooterSingle from "@/components/common/SelectRegionFooterSingle";
import {useShowSetting} from "@/app/ShowSettingProvider";


const MyComponent = ({data, forMobile, loader, region}) => {
    // const store = useSelector(store => store?.home)
    const [show, setShow] = useState(false);
    const [isToggled, setIsToggled] = useState(region ? region : []);

    const router = usePathname();



    // menu fixed on scroll
    useEffect(() => {
        if (document.body.classList.contains('scroll-down')) {
            document.body.classList.remove('scroll-down');
        }
    });

    useEffect(() => {
        const body = document.body;
        const scrollUp = 'scroll-up';
        const scrollDown = 'scroll-down';
        let lastScroll = 0;
        let howMuchScroll;

        if (window.screen.width < 991) {
            howMuchScroll = 150;
        } else {
            howMuchScroll = 150;
        }

        window.addEventListener('scroll', () => {
            let currentScroll = window.pageYOffset;

            if (currentScroll <= howMuchScroll) {
                body.classList.remove(scrollUp);
                body.classList.remove(scrollDown);
                return;
            }

            if (currentScroll > lastScroll && currentScroll > howMuchScroll) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            } else if (currentScroll < lastScroll && currentScroll > howMuchScroll) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }

            lastScroll = currentScroll;
        });
    }, []);


    //----- mobile menu action
    useEffect(() => {
        let getMobileMenuBar = document.querySelector('.main-menu-mobile');
        let getItemsParent = document.querySelector('.main-menu-mobile__items');

        let getItems = document.querySelectorAll('.main-item-for-click');
        let getItemsChild = document.querySelectorAll('.main-item-sub');
        let getItemsChildMenu = document.querySelectorAll('.menu-2');

        let getBacks = document.querySelectorAll('.sub-menu-back');
        let getBacksSub = document.querySelectorAll('.sub-menu-back-back');
        let getHamburgerClick = document.querySelector('#open-click')
        let getHamburgerCloseClick = document.querySelector('#close-click')
        let tl = new TimelineLite();
        let tl2 = new TimelineLite();

        // menu open toggle
        if (getHamburgerClick) {
            getHamburgerClick.addEventListener('click', () => {
                getMobileMenuBar.classList.add('menu-open')
                document.body.classList.add('stop-scroll')
                tl2.to(getItemsParent, {
                    duration: .2,
                    display: 'block',
                }).to(getItemsParent, {
                    duration: .2,
                    x: 0
                }, '-=.2')


            })

        }

        if (getHamburgerCloseClick) {
            getHamburgerCloseClick.addEventListener('click', () => {
                getMobileMenuBar.classList.remove('menu-open')
                document.body.classList.remove('stop-scroll')
                if (document.querySelector('.main-item.active')) {
                    getItemsParent.classList.remove('active')
                    getItemsParent.classList.remove('open-sub')
                    document.querySelector('.main-item.active').classList.remove('active')
                }
                if (document.querySelector('.main-item-sub.active')) {
                    getItemsParent.classList.remove('active')
                    getItemsParent.classList.remove('open-sub')
                    document.querySelector('.main-item-sub.active').classList.remove('active')
                }

                tl2.to(getItemsParent, {
                    duration: .2,
                    x: '100%'
                }).to(getItemsParent, {
                    duration: .2,
                    display: 'none'
                })
            });

        }


        // sub menu toggle
        getItems.forEach(i => {
            i.addEventListener('click', () => {
                getItemsParent.classList.add('active')
                i.classList.add('active')
            })
        })


        // sub menu toggle
        getItemsChild.forEach(i => {
            i.addEventListener('click', () => {
                getItemsParent.classList.add('active')
                getItemsParent.classList.add('open-sub')
                i.classList.add('active')
            })
        })

        getBacks.forEach(i => {
            i.addEventListener('click', (e) => {

                getItemsParent.classList.remove('active')
                document.querySelector('.main-item-sub').classList.remove('active')
                i.parentNode.parentElement.classList.remove('active')
                e.stopPropagation()
            })
        })
        getBacksSub.forEach(i => {
            i.addEventListener('click', (e) => {
                getItemsParent.classList.remove('open-sub')
                e.stopPropagation()
            })
        })

        // on click a tag menu hide
        let getAlla = document.querySelectorAll('.main-menu-mobile a');
        getAlla.forEach(i => {
            i.addEventListener('click', (e) => {
                // e.stopPropagation();
                getMobileMenuBar.classList.remove('menu-open');
                document.body.classList.remove('stop-scroll');
                setTimeout(() => {
                    if (document.querySelector('.main-item.active')) {

                        getItemsParent.classList.remove('active')
                        getItemsParent.classList.remove('open-sub')
                        document.querySelector('.main-item.active').classList.remove('active')
                    }
                }, 300)

                tl2.to(getItemsParent, {
                    duration: .3,
                    x: '100%'
                }).to(getItemsParent, {
                    duration: .3,
                    display: 'none'
                })
            })
        })

    }, [])


    // on click hide menu
    useEffect(() => {
        const getAList = document.querySelectorAll('.main-menu__left li');
        getAList.forEach(e => {
            e.addEventListener('click', function (i) {

                if (e.querySelector('ul')) {
                    e.querySelector('ul').style.display = 'none'
                }
                setTimeout(() => {
                    const getUl = document.querySelectorAll('.main-menu__left li ul')
                    getUl.forEach(e => {
                        e.removeAttribute('style')
                    })
                }, 300)

            });
        })

        // const getA = document.querySelectorAll('.main-menu__left li ul li a');
        // getA.forEach(e => {
        //     e.addEventListener('click', function (i) {
        //         document.querySelector('.main-menu').style.display = 'none'
        //         setTimeout(() => {
        //             document.querySelector('.main-menu').removeAttribute('style')
        //         }, 500)
        //     });
        // })

    }, [])



    let countryOptions = [];
    const showSetting = useShowSetting();

    let studyArray = showSetting?.region


    // Check if universityArray is defined and not empty
    if (studyArray && studyArray.length > 0) {
        countryOptions = studyArray.map(e => ({
            value: e?.category_data?.slug,
            label: reactHtmlParser(e?.category_data?.title),
            icon: e?.images?.list?.[0]?.full_path,
            short: e?.category_data?.description,
            id: e?.category_data?.id,

        }));
    }
    // Handle country selection


// Check localStorage when the component mounts
    useEffect(() => {
        const storedRegion = localStorage.getItem('region');
        if (storedRegion) {
            setIsToggled(JSON.parse(storedRegion));
        }
    }, [loader]);
    ;


// Listen for localStorage changes
    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'region') {
                setIsToggled(JSON.parse(event.newValue));
            }
        };

        // Add event listener
        window.addEventListener('storage', handleStorageChange);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);


    return (

        <>

            {/*mobile menu*/}
            <StyledMobileMenu className='main-menu-mobile'>
                <div className="main-menu-mobile__bar">
                    <div className="main-menu-mobile__bar__logo">
                        <Link prefetch={true}  href={'/'}><img alt='Maximus Education and Migration' width={168} height={40} src={'/images/static/logo.svg'}/></Link>
                        <Link prefetch={true}  href={'/'}><img alt='Maximus Education and Migration' width={168} height={40} src={'/images/static/logo-black.svg'}/></Link>
                    </div>

                    <div className="main-menu-mobile__bar__hamburger">
                        <ul>
                            <li className='hover-here'>

                                <svg id='open-click' xmlns="http://www.w3.org/2000/svg" width="21" height="11" viewBox="0 0 21 11">
                                    <g id="Group_26133" data-name="Group 26133" transform="translate(-330 -36)">
                                        <line id="Line_12437" data-name="Line 12437" x2="20" transform="translate(330.5 36.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                        <line id="Line_12438" data-name="Line 12438" x2="20" transform="translate(330.5 41.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                        <line id="Line_12439" data-name="Line 12439" x2="20" transform="translate(330.5 46.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                    </g>
                                </svg>


                                <svg id='close-click' xmlns="http://www.w3.org/2000/svg" width="15.557" height="15.558" viewBox="0 0 15.557 15.558">
                                    <line id="Line_12438" data-name="Line 12438" x2="20" transform="translate(0.707 0.707) rotate(45)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                    <line id="Line_12442" data-name="Line 12442" x2="20.001" transform="translate(14.85 0.708) rotate(135)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1"/>
                                </svg>


                            </li>
                        </ul>
                    </div>
                </div>

                <div className="main-menu-mobile__items">
                    <ul>

                        <li className={'menu-btn'}>
                            <SelectRegionFooterSingle
                                selectedValue={!isToggled ? countryOptions?.[0] :  isToggled  }
                                selection={countryOptions}
                                onChange={forMobile}
                                border="white"
                            />
                            {/*<SelectRegionFooter selectedValue={isToggled} selection={countryOptions} onChange={handleClickCountry} border={white}/>*/}
                        </li>
                        <li className={'menu-btn'}>
                            <Button width={'100%'} src={'/contact-us'} height={'50'} borderRadius={'25'}  text={'Contact us'}/>

                        </li>
                        <li className={'menu-btn last'}>
                            <Button src={'/search'} border={'1px solid white'} height={'50'} borderRadius={'25'} icon={'/images/static/search.svg'} width={'100%'} borderColor={white} background={'transparent'} color={white} text={'Search'}/>
                        </li>


                        <li className='main-item '>
                            <Link prefetch={true}  href={'/'}> Home</Link>
                            <span></span>
                        </li>

                        <li className='main-item main-item-for-click'>
                            For Students
                            <span className={'parent-arrow'}><img src="/images/static/mb-arrow.svg" alt="Maximus Education and Migration"/></span>
                            <ul className={'menu-1'}>
                                <p className='sub-menu-back'>
                                    <svg id="Group_26173" data-name="Group 26173" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                        <g id="Arrow" transform="translate(16) rotate(90)">
                                            <circle id="Ellipse_20" data-name="Ellipse 20" cx="8" cy="8" r="8" fill="#fff"/>
                                            <g id="Group_4834" data-name="Group 4834" transform="translate(5 6.5)">
                                                <line id="Line_5" data-name="Line 5" x2="3" y2="3" fill="none" stroke="#363231" strokeLinecap="round" strokeWidth="1"/>
                                                <line id="Line_6" data-name="Line 6" x1="3" y2="3" transform="translate(3)" fill="none" stroke="#363231" strokeLinecap="round" strokeWidth="1"/>
                                            </g>
                                        </g>
                                    </svg>


                                    <strong>For Students</strong>
                                </p>
                                {/*<h3>About Us</h3>*/}
                                <li><Link prefetch={true} href={'/universities'}>
                                    Universities
                                </Link></li>
                                <li><Link prefetch={true} href={'/courses'}>
                                    Courses
                                </Link></li>

                                <li><Link prefetch={true} href={'/schools'}>
                                    Schools
                                </Link></li>
                                <li className={'main-item-sub'}>
                                    Destinations
                                    <span className={'parent-arrow'}><img src="/images/static/mb-arrow.svg" alt="Maximus Education and Migration"/></span>
                                </li>
                                <li><Link prefetch={true} href={'/ielts-pte'}>
                                    IELTS & PTE
                                </Link></li>


                            </ul>
                            <ul className={'menu-2'}>
                                <p className='sub-menu-back-back'>
                                <svg id="Group_26173" data-name="Group 26173" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                        <g id="Arrow" transform="translate(16) rotate(90)">
                                            <circle id="Ellipse_20" data-name="Ellipse 20" cx="8" cy="8" r="8" fill="#fff"/>
                                            <g id="Group_4834" data-name="Group 4834" transform="translate(5 6.5)">
                                                <line id="Line_5" data-name="Line 5" x2="3" y2="3" fill="none" stroke="#363231" strokeLinecap="round" strokeWidth="1"/>
                                                <line id="Line_6" data-name="Line 6" x1="3" y2="3" transform="translate(3)" fill="none" stroke="#363231" strokeLinecap="round" strokeWidth="1"/>
                                            </g>
                                        </g>
                                    </svg>


                                    <strong>Destinations</strong>
                                </p>

                                <li><Link  prefetch={true} href={'/destination/australia'}>
                                    Australia
                                </Link></li>

                                <li><Link  prefetch={true} href={'/destination/malaysia'}>
                                    Malaysia
                                </Link></li>
                                <li><Link  prefetch={true} href={'/destination/united-kingdom'}>
                                    United Kingdom
                                </Link></li>
                            </ul>

                        </li>
                        <li className='main-item'>
                            <Link  prefetch={true} href={'/migrate-to-australia'}> Migration</Link>
                            <span></span>
                        </li>
                        <li className='main-item'>
                            <Link  prefetch={true} href={'/about-us'}> About Us</Link>
                            <span></span>
                        </li>
                        <li className='main-item'>
                            <Link  prefetch={true} href={'/news-events'}> News & Events</Link>
                            <span></span>
                        </li>
                    </ul>
                </div>

            </StyledMobileMenu>
        </>

    );
};


// mobile menu

const StyledMobileMenu = styled.section`
    position: fixed;
    width: 100%;
    z-index: 99999;
    top: 0;
    left: 0;
    transition: 0.7s all ${Transition};
    display: none;

    @media (max-width: 1170px) {
        display: block;
    }

    .menu-btn {
        height: 50px;
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 20px !important;
        display: flex;
        align-items: center;
        margin-bottom: 20px !important;
        max-width: calc(100vw - 30px);

        a {
            font-size: 16px !important;
            font-weight: 500 !important;
            line-height: 20px !important;
            height: 100%;
            text-align: center;
            vertical-align: middle;
            display: inline-flex !important;
            align-items: center;
            justify-content: center;
            color: #191919 !important;

            img {
                margin-right: 10px;
            }
        }

        &.last {
            margin-bottom: 60px !important;
        }
    }

    .menu-2 {
        transform: translate3d(200vw, 0, 0) !important;
        background: ${text};
    }

    .main-menu-mobile__bar__logo{
        a{
            &:last-child{
                display: none;
            }
        }
    }

    .main-menu-mobile__bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(242, 242, 242, 0.5);
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;
        background-color: rgba(34, 34, 34, 0.40);
        z-index: 999;
        transition: all .4s ease;
        height: 80px;
        padding: 0 15px;


        &__hamburger {
            ul {
                height: 40px;
                width: 40px;
                display: flex;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                background: ${hover};

                li {
                    //&:nth-of-type(1) {
                    //  margin-right: 15px;
                    //}
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {

                        #Line_12439 {
                            transition: 0.6s all ${Transition};
                        }

                        &:nth-of-type(2) {
                            display: none;
                        }
                    }

                    &.hover-here {
                        #Line_12439 {
                            r: 10px;
                        }
                    }
                }
            }
        }


    }


    .main-menu-mobile__items {
        padding: 150px 15px 80px;
        position: relative;
        transform-origin: top left;
        transition: all .3s ease-out;
        height: calc(${`105svh`});
        overflow-y: auto;
        overflow-x: hidden;
        background-color: ${text};
        width: 200vw;
        transform: translateX(100%);
        display: none;

        ul {
            width: 100%;
            overflow-y: auto;
            height: calc(100vh - 40px);

            li {
                text-transform: capitalize;
                display: block;
                width: 100%;
                color: #f2f2f2;
                font-size: 20px;
                line-height: 28px;
                font-weight: 500;
                //position: relative;

                &.main-item {

                    span {
                        content: '';
                        width: calc(100vw - 30px);
                        background-color: #FFFFFF;
                        height: 1px;
                        display: block;
                        margin-top: 30px;
                        position: relative;

                        img {
                            position: absolute;
                            right: 0;
                            bottom: 30px;
                        }
                    }
                    a {
                        color: #FFFFFF;
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: bold;
                    }
                }

                &.main-item-sub {
                    span {
                        content: '';
                        width: calc(100vw - 30px);
                        background-color: #FFFFFF;
                        height: 1px;
                        display: block;
                        margin-top: 30px;
                        position: relative;
                        img {
                            position: absolute;
                            right: 0;
                            bottom: 30px;
                        }
                    }
                    a {
                        color: #FFFFFF;
                        font-size: 20px;
                        line-height: 28px;
                        letter-spacing: -0.2px;
                        font-weight: 600;
                    }
                }

                &:hover {
                    color: ${hover};
                }

                &:not(:nth-last-of-type(1)) {
                    margin-bottom: 30px;

                }

                a {
                    color: #FFFFFF;
                    font-size: 20px;
                    line-height: 28px;
                    letter-spacing: -0.2px;
                    font-weight: 600;
                }

                //sub menus

                ul {
                    left: 0;
                    list-style: none;
                    margin: 0;
                    position: absolute;
                    top: 130px;
                    padding: 0 15px 25px;
                    //height: 100vh;
                    opacity: 0;
                    transform: translate3d(100vw, 0, 0);
                    transition: all .3s ease;
                    width: 100vw;

                    p {
                        margin-bottom: 35px;

                        svg {
                            margin-top: -5px;
                        }

                        strong {
                            border-color: transparent !important;
                            font-size: 20px;
                            font-weight: 500;
                            color: white;
                            padding-left: 20px;
                            line-height: 28px;
                        }

                    }

                    h3 {
                        font-size: 30px;
                        line-height: 40px;
                        font-weight: 400;
                        text-transform: capitalize;
                        padding-bottom: 20px;
                        border-bottom: 1px solid ${hover};
                        margin-bottom: 40px;
                    }

                    li {
                        &:not(:nth-last-of-type(1)) {
                            //margin-bottom: 30px;
                            margin-bottom: 20px;

                            a {
                                border-bottom: 1px solid rgba(198, 198, 198, 0.5);
                            }
                        }

                        a {
                            color: #FFFFFF;
                            font-size: 20px;
                            line-height: 28px;
                            letter-spacing: -0.2px;
                            font-weight: 600;
                            text-transform: capitalize;
                            display: block;
                            width: 100%;

                            padding-bottom: 15px;
                        }

                        ul {
                            left: 0;
                            list-style: none;
                            margin: 0;
                            position: absolute;
                            top: 130px;
                            padding: 0 15px 25px;
                            //height: 100vh;
                            opacity: 0;
                            transform: translate3d(100vw, 0, 0);
                            transition: all .3s ease;
                            width: 100vw;

                            p {
                                margin-bottom: 35px;

                                svg {
                                    margin-top: -5px;
                                }

                                strong {
                                    border-color: transparent !important;
                                    font-size: 20px;
                                    font-weight: 500;
                                    color: white;
                                    padding-left: 20px;
                                    line-height: 28px;
                                }

                            }

                            h3 {
                                font-size: 30px;
                                line-height: 40px;
                                font-weight: 400;
                                padding-bottom: 20px;
                                border-bottom: 1px solid ${hover};
                                margin-bottom: 40px;
                            }

                            li {
                                &:not(:nth-last-of-type(1)) {
                                    //margin-bottom: 30px;
                                    margin-bottom: 20px;

                                    a {
                                        border-bottom: 1px solid rgba(198, 198, 198, 0.5);
                                    }
                                }

                                a {
                                    color: #FFFFFF;
                                    font-size: 20px;
                                    line-height: 28px;
                                    letter-spacing: -0.2px;
                                    font-weight: 600;
                                    display: block;
                                    width: 100%;
                                    padding-bottom: 15px;
                                }

                            }
                        }


                    }
                }

                &.active {
                    ul {
                        opacity: 1;
                        z-index: 2;
                    }
                }

            }
        }

        &.active {
            transform: translate3d(-100vw, 0, 0) !important;
            
            &.open-sub{
              .menu-2{
                  transform: translate3d(100vw, 0, 0) !important;
              }
                
            }
            
        }
    }

    //toggle action

    &.menu-open {
        .main-menu-mobile__bar {
            background-color: ${text};

            &__logo {
                img {
                        // content: url(${"LogoBlack"});
                }
            }

            &__hamburger {
                li:nth-of-type(1) {
                    svg {
                        g {
                            stroke: ${text};
                        }

                        path {
                            fill: ${text};
                        }
                    }
                }

                #open-click {
                    display: none;
                }

                #close-click {
                    display: block;
                }
            }
        }
    }

    @media (max-width: 600px) {
        .menu-btn {
            width: 100%;
        }
    }
    
 
`;

export default React.memo(MyComponent);