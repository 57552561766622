// components/ModalContent.jsx
import React, {useContext} from 'react';
import { Loading } from '@/components/common/Loading';
import LandingPopup from '@/components/common/LandingPopup';
import NavigationMenuInner from "@/components/common/globals/NavigationMenuInner";
import {gsap} from "gsap";
import PageTransition from "@/components/common/animations/PageTransition";

const ModalContent = ({ pathName, showNotice, showSetting, loader , closeNotice, showRegion}) => {


    return(
        <>

            {showNotice && pathName === '/' && !showRegion && <LandingPopup region={showSetting} data={showSetting} show={showNotice} onClose={closeNotice} />}
            {
                loader &&
                <Loading />
            }

        </>
    )
};

export default React.memo(ModalContent);
