// components/HeaderContent.jsx
import React, {useContext} from 'react';
import MobileMenu from '@/components/common/globals/MobileMenu';
import Menu from '@/components/common/globals/Menu';
import {Toaster} from 'sonner';
import NavigationMenuInner from "@/components/common/globals/NavigationMenuInner";
import {usePathname} from "next/navigation";


const HeaderContent = ({ isMobile, showSetting, showRegion ,onClick, forMobile , loader, setShowLoader }) => {

    const pathName = usePathname();



    return (
        <>
            <Toaster richColors/>
            {isMobile ? <MobileMenu data={showSetting} region={showRegion} loader={loader}  forMobile={forMobile}/> : <Menu setShowLoader={setShowLoader} onClick={onClick} data={showSetting} region={showRegion}/>}
            {
                pathName === '/courses' || pathName === '/universities' || pathName === '/schools' || pathName === '/ielts-pte' ?
                <NavigationMenuInner/> : ''

            }
        </>
    )
}


export default React.memo(HeaderContent);
