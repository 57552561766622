"use client";
import StyledComponentsRegistry from "@/lib/registry";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyle from "@/styles/globalStyle";
import {usePathname,} from "next/navigation";
import React, {Suspense, useEffect, useState} from "react";
import {gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {FadeUp, ParallaxX, ParallaxXR, ParallaxXX, splitLeft, SplitUp,} from "@/components/common/animations/TextAnimations";
import StickyButton from "@/components/common/StickyButton";
import useDeviceType from "@/components/hooks/useDeviceType";
import ErrorBoundary from "@/components/ErrorBoundary";
import {getSettingApi} from "@/api";
import {useSmoothScroll} from "@/components/hooks/useSmoothScroll";
import {usePageClass} from "@/components/hooks/usePageClass";
import SEO from "@/components/hooks/SEO";
import HeaderContent from "@/components/common/globals/HeaderContent";
import ModalContent from "@/components/common/globals/ModalContent";
// import css
import "./global.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import CustomContextMenu from "@/components/common/CustomContextMenu";
import PageTransition from "@/components/common/animations/PageTransition";
import Footer from "@/components/common/globals/Footer";
import {toast} from "sonner";
import {ShowSettingProvider} from "@/app/ShowSettingProvider";
import reactHtmlParser from "react-html-parser";
import GoogleTagManager from "@/components/GTM";
import GoogleAnalytics from "@/components/GoogleAnalytics";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);

export default function RootLayout({ children }) {
  const pathName = usePathname();
  const isMobile = useDeviceType();
  const [showLogo, setShowLogo] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showRegion, setShowRegion] = useState('');
  const [isFirstLoad, setIsFirstLoad] = useState(true);  // Track first load for PageTransition
  // initialize smooth scroll
  const el = useSmoothScroll(isMobile);
  usePageClass(pathName);

  // Combined effect to handle region and notice logic
  // useEffect(() => {
  //   const regionData = localStorage?.getItem('region');
  //   if (regionData) {
  //     const region = JSON.parse(regionData);
  //     setShowRegion(region);
  //   }
  //   // Get saved data from sessionStorage
  //   let data = sessionStorage.getItem("loader");
  //
  //   if(!data){
  //     setShowLogo(true)
  //   }
  //
  //
  //   const fetchSettingData = async (regionId = '') => {
  //     try {
  //       const getSettingData = await getSettingApi(regionId);
  //       setShowSetting(getSettingData);
  //     } catch (error) {
  //       console.error("Failed to fetch settings:", error);
  //     }
  //   };
  //
  //   const initializeSettings = async () => {
  //     if (regionData) {
  //       const cooke = JSON.parse(regionData);
  //       setShowRegion(cooke);
  //
  //       if (cooke?.id) {
  //         await fetchSettingData(cooke.id);
  //       } else {
  //         await fetchSettingData();
  //       }
  //     } else {
  //       await fetchSettingData();
  //     }
  //   };
  //
  //   // Call the async function to initialize settings
  //   initializeSettings();
  //
  // }, [pathName]);
  // // Effect to check if it's the first load and set to false after the first render
  // useEffect(() => {
  //   const firstVisit = sessionStorage.getItem("firstVisit");  // Using session storage to persist across refreshes
  //   if (!firstVisit) {
  //     setIsFirstLoad(true);
  //     sessionStorage.setItem("firstVisit", "no");  // Mark as visited after the first load
  //   } else {
  //     setIsFirstLoad(false);
  //   }
  // }, [pathName]);
  useEffect(() => {
    const regionData = localStorage?.getItem('region');
    if (regionData) {
      const region = JSON.parse(regionData);
      setShowRegion(region);
    }

    setShowNotice(true);

    const fetchSettingData = async (regionId = '') => {
      try {
        const getSettingData = await getSettingApi(regionId);
        setShowSetting(getSettingData);
      } catch (error) {
        console.error("Failed to fetch settings:", error);
      }
    };

    const initializeSettings = async () => {
      if (regionData) {
        const cooke = JSON.parse(regionData);
        setShowRegion(cooke);

        if (cooke?.id) {
          await fetchSettingData(cooke.id);
        } else {
          await fetchSettingData();
        }
      } else {
        await fetchSettingData();
      }
    };

    // Call the async function to initialize settings
    initializeSettings();
  }, [pathName]);





  // const closeNotice = async (e) => {
  //   setShowLoader(true);
  //   gsap.to('.notice_modal', {
  //     opacity: 0,
  //     y: -50,
  //     ease: 'power2.out',
  //   });
  //   setShowNotice(false);
  //   setTimeout(() => {
  //     setShowLoader(false);
  //   }, 500);
  //
  //   const regionData = localStorage?.getItem('region');
  //   if (regionData) {
  //     const cooke = JSON.parse(regionData);
  //     setShowRegion(cooke);
  //
  //     if (cooke) {
  //       try {
  //         const getSettingData = await getSettingApi(cooke?.id);
  //         setShowSetting(getSettingData);
  //       } catch (error) {
  //         console.error("Failed to fetch settings:", error);
  //       }
  //     }
  //     else{
  //       toast.info('Please Select a Region First')
  //     }
  //   }
  //   else{
  //     toast.info('Please Select a Region First')
  //   }
  // };

  const closeNotice = async (e) => {
    const regionData = localStorage?.getItem('region');

    let regionArray = showSetting?.region
    let countryOptions = [];

    // Check if universityArray is defined and not empty
    if (regionArray && regionArray.length > 0) {
      countryOptions = regionArray.map(e => ({
        value: e?.category_data?.slug,
        label: reactHtmlParser(e?.category_data?.title),
        icon: e?.images?.list?.[0]?.full_path,
        short: e?.category_data?.description,
        id: e?.category_data?.id,

      }));


    }

    // Check if region data exists
    if (!regionData) {
      // setShowLoader(false);  // Hide the loader
      // toast.info('Please Select a Region First');  // Show the info message
      // Proceed with the closing animation and further logic if region data is present

      setShowLoader(true);
      gsap.to('.notice_modal', {
        opacity: 0,
        y: -50,
        ease: 'power2.out',
      });
      setShowNotice(false);

      setTimeout(() => {
        setShowLoader(false);
      }, 500);
      localStorage?.setItem('region',JSON.stringify(countryOptions?.[0]));

      const cooke = countryOptions?.[0];
      setShowRegion(cooke);

      if (cooke) {
        try {
          const getSettingData = await getSettingApi(cooke?.id);
          setShowSetting(getSettingData);
        } catch (error) {
          console.error("Failed to fetch settings:", error);
        }
      }
      // return;
    }

    // Proceed with the closing animation and further logic if region data is present
    setShowLoader(true);
    gsap.to('.notice_modal', {
      opacity: 0,
      y: -50,
      ease: 'power2.out',
    });
    setShowNotice(false);

    setTimeout(() => {
      setShowLoader(false);
    }, 500);

    const cooke = JSON.parse(regionData);

    if (cooke) {
      try {
        setShowRegion(cooke);
        const getSettingData = await getSettingApi(cooke?.id);
        setShowSetting(getSettingData);
      } catch (error) {
        console.error("Failed to fetch settings:", error);
      }
    }
  };


  // initialize animation
  splitLeft();
  ParallaxX();
  ParallaxXR();
  FadeUp();
  SplitUp();
  ParallaxXX()

  // Dynamically inject the ANZSCO widget script for the specific pathname
  useEffect(() => {
    const scriptId = 'anz-snippet';
    const widgetId = 'ANZSCO_Widget';

    if (pathName === '/migrate-to-australia') {
      // Check if the script is already added
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = 'https://searchmyanzsco.com.au/js/anzsco_widget.js?key=cA-D4jIEeEs=';
        script.async = true;

        document.body.appendChild(script);
      }
    } else {
      // Remove the script if navigating away from '/migrate-to-australia'
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        existingScript.remove();
      }

      // Remove the widget if it exists
      const existingWidget = document.getElementById(widgetId);
      if (existingWidget) {
        existingWidget.remove();
      }
    }

    // Cleanup on component unmount or pathname change
    return () => {
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        existingScript.remove();
      }

      const existingWidget = document.getElementById(widgetId);
      if (existingWidget) {
        existingWidget.remove();
      }
    };
  }, [pathName]);

  useEffect(() => {
    // Dynamically inject GTM script into the head
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': 
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id=GTM-KQX6WRXC';f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-KQX6WRXC');
    `;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (

      <ShowSettingProvider showSetting={showSetting}>
        <html lang="en">
        <SEO
            title="Maximus Education and Migration"
            description="Maximus Education &amp; migration provides wide range of services for students to study in Melbourne including education consultation,visa &amp; migration services."
            keywords="Education, Universities , Australia, Migration"
            author="Maximus Education and Migration"
            url={`https://maximuseducation.com.au${pathName}`}
            image="/images/static/logo.svg"
            twitterHandle=""
            facebookVerification=""
            googleVerification="RMNN-IAbh8oMofhMNpVPPtc5dkKU_oBcEMo9Z-ylOgs"
            canonical={`https://maximuseducation.com.au${pathName}`}
        />

        <body suppressHydrationWarning={true}>
        {/* Google Tag Manager (noscript) */}
        <noscript>
          <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-KQX6WRXC"
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
        <StyledComponentsRegistry>
          <ErrorBoundary>
            <GlobalStyle/>
            <HeaderContent forMobile={closeNotice} loader={showLoader} isMobile={isMobile} showSetting={showSetting} onClick={closeNotice} showRegion={showRegion}/>
            {
                pathName === '/' &&
                <PageTransition/>
            }
            <ModalContent showRegion={showRegion}
                          pathName={pathName}
                          showNotice={showNotice}
                          showSetting={showSetting}
                          closeNotice={closeNotice}
                          loader={showLoader}
            />
            <StickyButton/>
            {
              isMobile ?
                  null :
                  <CustomContextMenu/>

            }
            <GoogleTagManager gtmId="GTM-KQX6WRXC" />

            <div ref={el} id="smooth-wrapper" style={{opacity: 1}}>
              <div id="smooth-content">
                {React.Children.map(children, child => {
                  return React.cloneElement(child, {showSetting});
                })}
                <Footer key={100} regionData={showSetting} onChange={closeNotice} region={showRegion}/>
              </div>
            </div>

          </ErrorBoundary>

        </StyledComponentsRegistry>
        </body>
        </html>
      </ShowSettingProvider>
  );
}
