// components/GoogleAnalytics.jsx
import { useEffect } from 'react';
import Script from 'next/script';

const GA_TRACKING_ID = 'G-SEEZX59NRN'; // Replace with your Google Analytics ID

const GoogleAnalytics = () => {
    useEffect(() => {
        const handleRouteChange = () => {
            const page_path = window.location.pathname;
            window.gtag('config', GA_TRACKING_ID, { page_path });
        };

        handleRouteChange(); // Initial load
        window.addEventListener('popstate', handleRouteChange); // Handle browser history changes

        return () => {
            window.removeEventListener('popstate', handleRouteChange);
        };
    }, []);

    return (
        <>
            {/* Load the Google Analytics script */}
            <Script
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
            />
            {/* Google Analytics initialization */}
            <Script
                id="google-analytics"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
                }}
            />
        </>
    );
};

export default GoogleAnalytics;
