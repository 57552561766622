import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import styled from 'styled-components';
import {Form} from 'react-bootstrap';
import {hardGray, hover, text, Transition, white} from '@/styles/globalStyleVars';
import Select, {components} from 'react-select';
import Cookies from "js-cookie";
import {usePathname} from "next/navigation";

// eslint-disable-next-line react/display-name
const SelectField = forwardRef(({selectedValue, clear, onChange, disabled, prev_id, color, placeholder, selection, option, background, border}, ref) => {


  const selectLocationInputRef = useRef();
  const filterboxRef = useRef(null);
  const [selectMenuLoc, setSelectMenuLoc] = useState('auto');
  const [selectLocation, setSelectLocation] = useState(selectedValue ? selectedValue : selection?.[0]);
  const location = usePathname();








  // Calculate the available space and set menuPlacement accordingly

  useEffect(() => {
    const handleScroll = () => {
      const filterboxElement = filterboxRef.current;
      if (filterboxElement) {
        const rect = filterboxElement.getBoundingClientRect();
        const { top, bottom } = rect;
        const viewportHeight = window.innerHeight;
        const topSpace = top;
        const bottomSpace = viewportHeight - bottom;

        if (topSpace > bottomSpace) {
          setSelectMenuLoc('top');
        } else {
          setSelectMenuLoc('bottom');
        }
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [filterboxRef]);



  useImperativeHandle(ref, () => ({
    clearValue: () => {
      if (selectLocationInputRef.current && selectLocationInputRef.current.select) {
        selectLocationInputRef.current.select.clearValue();
      }
    },
  }), [selectLocationInputRef]);







  const customStyles = {
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: 'all .7s cubic-bezier(.74,0,.24,.99)',
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      color: state.isSelected ? `${white}` : text,
      backgroundColor: state.isSelected ? hover : white,
      margin: 0,
      fontSize: 16,
      cursor: 'pointer',
      lineHeight: '20px',
      paddingLeft: 10,
      paddingRight: 10,
      fontWeight: state.isSelected ? 400 : 400,
      borderBottom: state.options.indexOf(state.data) === state.options.length - 1 ? 'none' : '1px solid #888888', // Check if it's the last item
      transition: 'all .5s cubic-bezier(.74,0,.24,.99)',

      '&:hover': {
        backgroundColor: `${hover}`,
        color: `${white}`,
        cursor: 'pointer'
      },

    }), menu: (provided, state) => ({
      ...provided,
      color: '#888888',
      backgroundColor: state.isSelected ? `${white}` : white,
      margin: '15px 0 0 0',
      border: `1px solid ${hardGray}`,
      padding: 0,
      borderRadius: 10,
      fontSize: 12,
      zIndex: 10,
      borderBottom: '1px solid #888888',
      transition: 'all .7s cubic-bezier(.74,0,.24,.99) !important',
      opacity:`1 !important`,
      duration: 1,
      delay:0.2,
      // width: 200,
    }), menuList: (provided, state) => ({
      ...provided,
      padding: 0,
      backgroundColor: state.isSelected ? `${white}` : white,
      borderRadius: 10,
      borderColor: hardGray,
      fontWeight: '400',
      color: '#FFF',
      fontSize: 12,
      duration: 1,
      transition: 'all .7s cubic-bezier(.74,0,.24,.99)',
      maxHeight: '180px', // Set the maximum height for the menu list
      overflowY: 'scroll', // Hide the scrollbar and enable scrolling
      scrollbarWidth: 'none', // Firefox
      '&::-webkit-scrollbar': {
        display: 'none !important',
      },
    }),

  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon/>
      </components.DropdownIndicator>
    );
  };
  const CaretDownIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="12.828" height="7.828" viewBox="0 0 12.828 7.828">
        <g id="Arrow" transform="translate(17238.414 7077.914)">
          <line id="Line_3886" data-name="Line 3886" x2="5" y2="5" transform="translate(-17237 -7076.5)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="2"/>
          <line id="Line_3887" data-name="Line 3887" x1="5" y2="5" transform="translate(-17232 -7076.5)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="2"/>
        </g>
      </svg>

    );
  };

  useEffect(() => {
    const handleScroll = (e) => {
      if (selectLocationInputRef.current?.select?.state.menuIsOpen) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    // Add event listener to prevent window scroll when the dropdown is open
    window.addEventListener('wheel', handleScroll, {passive: false});

    return () => {
      // Remove event listener when the component unmounts
      window.removeEventListener('wheel', handleScroll);
    };
  }, [location]);


  const handleLocation = (e) => {
    setSelectLocation(e);
    if (onChange) {
      onChange(e);
    }
  };


  useEffect(() => {
    if (localStorage?.getItem('region')) {
      setSelectLocation(JSON.parse(localStorage?.getItem('region')))
    }else {
      setSelectLocation(selection?.[0])

    }
  }, [onChange]);


  const Option = (props,index) => (
      <components.Option {...props} key={index} className="country-option">
        <img src={props.data.icon} alt="logo" height={24} width={24} className="country-logo" />
        <p>{props.data.label}</p>
      </components.Option>
  );


  const SingleValue = ({ children, ...props }) => (
      <components.SingleValue className={'single-value'} {...props}>
        <img src={props.data.icon} alt="s-logo" height={24} width={24} className="selected-logo" />
        <p>{children}</p>
      </components.SingleValue>
  );


  return (
    <StyledSelectField className={'selector-custom-nzuac'} background={background}>
      <div className="form-group" ref={filterboxRef }>
        <Form.Group controlId="formBasicPhone">


                <Select isDisabled={!!disabled}
                        classNamePrefix="filter"
                        ref={selectLocationInputRef}
                        isSearchable={false}
                        menuPlacement={selectMenuLoc}
                        isClearable={clear}
                        value={selectLocation ? selectLocation : selection?.[0]}
                        options={selection}
                        styles={customStyles}
                        onChange={handleLocation}
                        components={{ DropdownIndicator, Option, SingleValue }}
                        placeholder={placeholder}
                />



        </Form.Group>

      </div>
    </StyledSelectField>
  );
});


const StyledSelectField = styled.div`
  position: relative;
  min-width: 250px;

  .form-group {
    margin: 0;
  }
  .filter__placeholder{
    text-align: left;
    padding-left: 10px;
  }
  .country-option{
    display: flex;
    align-items: center;
    gap: 8px;
  }
  //react select

  .filter--is-disabled {
    //opacity: 0.3;
    cursor: not-allowed;

    .filter__placeholder, .filter__single-value {
      color: #888888 !important;
      text-align: left;
    }

    .filter__indicator {
      svg {
        line {
          stroke: #888888 !important;
        }
      }
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(2rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .menu-list {
    animation: fadeIn 0.2s ease-in-out;
  }
  .filter__menu{
    transition: 0.7s all ${Transition};
    opacity: 0;
  }
  .filter__control {
    height: 50px;
    font-size: 16px;
    font-weight: 400;
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid ${props => props.border || white};
    padding: 0 8px 0 10px;
    cursor: pointer;
    background: ${props => props.background || white};
    border-radius: 100px !important;
    max-width: 100%;
    @media (max-width: 992px) {
      max-width: 100%;

    }
    
    &:hover{
      border-color: ${text};
    }

    .filter__value-container, .filter__indicator {
      padding-left: 0;
      padding-right: 0;
    }

    .filter__placeholder {
      color: ${text};
      outline: none;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.16px;
      font-weight: 400;
    }

    .filter__single-value {
      color: ${text};
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.16px;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .filter__indicator-separator {
      display: none;
    }

    .filter__indicators, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .filter__control.filter__control--is-focused {
    border-color: ${text} !important;

    .filter__indicator {
      svg {
        line {
          //    stroke: #f1f0ee !important;;
        }
      }
    }
  }

  .filter__menu {


    ul {
      display: none;
    }

    .filter__menu_item {

      /* width */

      ::-webkit-scrollbar {
        display: none !important;
      }

      /* Track */

      ::-webkit-scrollbar-track {
        background: transparent;
        display: none;

      }

      /* Handle */

      ::-webkit-scrollbar-thumb {
        display: none;

      }

      /* Handle on hover */

      ::-webkit-scrollbar-thumb:hover {
        //background: transparent;
      }
      &:last-child {
        border: none !important;
      }
    }


  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;

  }




`;

export default React.memo(SelectField);














