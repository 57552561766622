// components/GoogleAnalytics.jsx
import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import Script from 'next/script';

const GA_TRACKING_ID = 'G-SEEZX59NRN'; // Replace with your Google Analytics ID

// Function to trigger page view tracking
const pageView = (url) => {
    window.gtag('config', GA_TRACKING_ID, {
        page_path: url,
    });
};

const GoogleAnalytics = () => {
    const pathname = usePathname();

    useEffect(() => {
        // Track page views when pathname changes
        if (pathname) {
            pageView(pathname);
        }
    }, [pathname]); // Trigger effect on pathname change

    return (
        <>
            {/* Load the Google Analytics script */}
            <Script
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
            />

            {/* Google Analytics initialization */}
            <Script
                id="google-analytics"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
                }}
            />
        </>
    );
};

export default GoogleAnalytics;
